"use strict";
/*
  Autogenerated, do not edit.
  All changes will be undone.
  types version: 0.0.0-dev-development
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiPolygon = void 0;
class MultiPolygon {
    constructor(values) {
        Object.assign(this, values);
    }
}
exports.MultiPolygon = MultiPolygon;
